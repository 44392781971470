import { render, staticRenderFns } from "./BuyLimit.vue?vue&type=template&id=6084c32e&scoped=true&"
import script from "./BuyLimit.vue?vue&type=script&lang=js&"
export * from "./BuyLimit.vue?vue&type=script&lang=js&"
import style0 from "./BuyLimit.vue?vue&type=style&index=0&id=6084c32e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6084c32e",
  null
  
)

export default component.exports