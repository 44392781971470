// 限时抢购
<template>
  <div>
    <common-header></common-header>
    <div class="buy-limit warp">
      <div class="bj"></div>
      <!-- 时间选项 start -->
      <swiper :options="limitSwiper" class="time-box" ref="limitSwiper">
        <swiper-slide class="time-slide">
          <div class="time-item" :class="tabIndex == 0 ? 'active' : ''">
            <div class="hours">12:00</div>
            <div class="ms">抢购进行中</div>
          </div>
        </swiper-slide>
        <swiper-slide class="time-slide">
          <div class="time-item" :class="tabIndex == 1 ? 'active' : ''">
            <div class="hours">12:00</div>
            <div class="ms">抢购进行中</div>
          </div>
        </swiper-slide>
      </swiper>
      <!-- 时间选项 end -->

      <!-- 商品列表轮播 start -->
      <div class="goods-swiper-box">
        <swiper :options="goodsSwiper" ref="goodsSwiper">
          <swiper-slide>
            <ul class="goods-ul">
              <li class="goods-li">
                <img class="goods-img" src="@/assets/images/demoimg/sp-img.png" alt="">
                <div class="right">
                  <div class="name">新鲜牛腱子 500g/份</div>
                  <div class="ms">正宗科尔沁 原切新鲜牛腱子500g </div>
                  <div class="progress-box">
                    <mt-progress :value="60"></mt-progress>
                    <div class="text">60%</div>
                  </div>
                  <div class="bottom-box">
                    <div class="price-box">
                      <div class="present-price">
                        <div class="unit">￥</div>
                        <div class="moeny">65</div>
                      </div>
                      <div class="org-price">￥88</div>
                    </div>
                    <div class="purchase jb-r-l">去抢购</div>
                  </div>
                </div>
              </li>
              <li class="goods-li">
                <img class="goods-img" src="@/assets/images/demoimg/sp-img.png" alt="">
                <div class="right">
                  <div class="name">新鲜牛腱子 500g/份</div>
                  <div class="ms">正宗科尔沁 原切新鲜牛腱子500g </div>
                  <div class="progress-box">
                    <mt-progress :value="60"></mt-progress>
                    <div class="text">60%</div>
                  </div>
                  <div class="bottom-box">
                    <div class="price-box">
                      <div class="present-price">
                        <div class="unit">￥</div>
                        <div class="moeny">65</div>
                      </div>
                      <div class="org-price">￥88</div>
                    </div>
                    <div class="purchase jb-r-l">去抢购</div>
                  </div>
                </div>
              </li>
            </ul>
          </swiper-slide>
          <swiper-slide>
            <ul class="goods-ul">
              <li class="goods-li">
                <img class="goods-img" src="@/assets/images/demoimg/sp-img.png" alt="">
                <div class="right">
                  <div class="name">新鲜牛腱子 500g/份</div>
                  <div class="ms">正宗科尔沁 原切新鲜牛腱子500g </div>
                  <div class="progress-box">
                    <mt-progress :value="60"></mt-progress>
                    <div class="text">60%</div>
                  </div>
                  <div class="bottom-box">
                    <div class="price-box">
                      <div class="present-price">
                        <div class="unit">￥</div>
                        <div class="moeny">65</div>
                      </div>
                      <div class="org-price">￥88</div>
                    </div>
                    <div class="purchase jb-r-l">去抢购</div>
                  </div>
                </div>
              </li>
              <li class="goods-li">
                <img class="goods-img" src="@/assets/images/demoimg/sp-img.png" alt="">
                <div class="right">
                  <div class="name">新鲜牛腱子 500g/份</div>
                  <div class="ms">正宗科尔沁 原切新鲜牛腱子500g </div>
                  <div class="progress-box">
                    <mt-progress :value="60"></mt-progress>
                    <div class="text">60%</div>
                  </div>
                  <div class="bottom-box">
                    <div class="price-box">
                      <div class="present-price">
                        <div class="unit">￥</div>
                        <div class="moeny">65</div>
                      </div>
                      <div class="org-price">￥88</div>
                    </div>
                    <div class="purchase jb-r-l">去抢购</div>
                  </div>
                </div>
              </li>
            </ul>
          </swiper-slide>
        </swiper>
      </div>
      <!-- 商品列表轮播 end -->



    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      tabIndex: 0,
      limitSwiper: {
        slidesPerView :'auto',
        spaceBetween: 10,
        on: {
          tap: (res)=> {
            let index = this.$refs.limitSwiper.swiper.clickedIndex;
            let goodsSwiper = this.$refs.goodsSwiper.swiper;
            goodsSwiper.slideTo(index, 500, false)
            this.tabIndex = index;
          }
        }
      },
      goodsSwiper: {
        spaceBetween: 10,
        on: {
          slideChangeTransitionStart: ()=> {
            let index = this.$refs.goodsSwiper.swiper.activeIndex;
            this.tabIndex = index;
          },
        },
      },
    }
  },
  mounted() {

  },
  methods: {

  },
}
</script>
<style lang='less' scoped>
.bottom-box{
  display: flex;
  justify-content: space-between;
  margin-top: .45rem;
  .purchase{
    width: 1.6rem;
    height: .58rem;
    border-radius: .3rem;
    font-size: .3rem;
    color: #ffffff;
    text-align: center;
    line-height: .58rem;
  }
}
.goods-swiper-box{
  margin-top: .3rem;
}
.goods-li{
  background: #fff;
  border-radius: .1rem;
  display: flex;
  padding: .2rem;
  margin-bottom: .2rem;
  .price-box{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .org-price{
    font-size: .26rem;
    color: #bfbfbf;
    text-decoration: line-through;
    line-height: 1;
    margin-left: .1rem;
  }
  .present-price{
    display: flex;
    align-items: center;
    color: #ff7241;
    .unit{
      font-size: .24rem;
    }
    .moeny{
      font-size: .42rem;
      line-height: 1;
    }
  }
  .name{
    font-size: .3rem;
    color: #262626;
  }
  .ms{
    font-size: .26rem;
    color: #bfbfbf;
    margin-top: .1rem;
    margin-bottom: .14rem;
  }
  .goods-img{
    width: 2.2rem;
    height: 2.2rem;
    margin-right: .2rem;
  }
  .progress-box{
    width: 2rem;
    position: relative;
    .text{
      position: absolute;
      color: #fff;
      font-size: .18rem;
      text-align: center;
      width: 100%;
      top: 0;
      left: 0;
      line-height: .2rem;
    }
  }
}
.time-slide{
  width: 1.44rem !important;
  height: 1.1rem;
}
.time-item{
  width: 1.44rem !important;
  height: .96rem;
  border-radius: .05rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
  opacity: .5;
  &.active{
    opacity: 1;
    position: relative;
    &::before{
      position: absolute;
      transform: rotate(180deg);
      bottom: -.15rem;
      left: 50%;
      margin-left: -.075rem;
      content: "";
      width: 0;
      height: 0;
      border-width: 0px .15rem .15rem;
      border-style: solid;
      border-color: transparent transparent #fff;
    }
  }
  .hours{
    font-size: .32rem;
    color: #262626;
  }
  .ms{
    font-size: .22rem;
    color: #262626;
  }
}
.buy-limit{
  position: relative;
  padding-top: .35rem;
  .bj{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    background: rgba(255,125,74,1);
    background: -moz-linear-gradient(top, rgba(255,125,74,1) 0%, rgba(247,247,247,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,125,74,1)), color-stop(100%, rgba(247,247,247,1)));
    background: -webkit-linear-gradient(top, rgba(255,125,74,1) 0%, rgba(247,247,247,1) 100%);
    background: -o-linear-gradient(top, rgba(255,125,74,1) 0%, rgba(247,247,247,1) 100%);
    background: -ms-linear-gradient(top, rgba(255,125,74,1) 0%, rgba(247,247,247,1) 100%);
    background: linear-gradient(to bottom, rgba(255,125,74,1) 0%, rgba(247,247,247,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7d4a', endColorstr='#f7f7f7', GradientType=0 );
  }
}
</style>


